import './ServiceContent.css'
import React, { useState } from 'react';
import { services } from '../..//data';
import 'bootstrap-icons/font/bootstrap-icons.css';


export default function ServiceContent() {

    const [text, setText] = useState(services[0].text);
    const [longTopic, setlongTopic] = useState(services[0].longTopic);

    const [activeTopic, setActiveTopic] = useState(services[0].topic);


    const handleClick = (topic, text, longTopic) => {
        setActiveTopic(topic === activeTopic ? null : topic);
        setText(text)
        setlongTopic(longTopic)
    };

    let counter = 0;

    return (
        <div className='main-service'>
            <h1 className='mentors-h1' id='aboutSection'>С чем мы помогаем?</h1>

            <div className='service-div-middle'>
                <div className='right-service-section'>
                {services.map((service) => (
                    <div className={activeTopic === service.topic ? 'service-smallest-div-clicked' :  'service-smallest-div'} onClick={() => handleClick(service.topic, service.text, service.longTopic)}>
                        <i class={ "bi bi-" + counter++ + "-circle-fill" }></i>
                        <a className='service-a-topic'>{service.topic}</a>
                    </div>
                ))}

                </div>
                <div className='left-service-section'>
                    <div className='left-middle-section'>
                        <h4 className='long-topic'>{longTopic}</h4>
                        <p className='topic-text'>{text}</p>
                        
                    </div>
                </div>
                
            </div>
        </div>
    )
}