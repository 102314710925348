import './Footer.css'

export default function Footer(){
    return (
        <footer>
            <div className='footer-div' id='footerSection'>
            <h1 className='footer-h1'>BeyondBorders</h1>
                <div className='social-media-div'>
                    {/* <h3 className='footer-topic'>Мы в социальных сетях</h3> */}
                    <div className='social-icons'>
                        <div className='social-small-div'>
                            <a href='https://www.instagram.com/beyondborders.kg/'><img src='icons/instagram.png' className='social-icon'/></a>
                            <a className='a-social' href='https://www.instagram.com/beyondborders.kg/' >Instagram</a>
                        </div>
                        <div className='social-small-div'>
                            <a href='https://www.tiktok.com/@beyondborderskg'><img src='icons/tiktok.png' className='social-icon'/></a>
                            <a className='a-social' href='https://www.tiktok.com/@beyondborderskg'>TikTok</a>
                        </div>
                    </div>
                </div>
                <div className='social-media-div'>
                    {/* <h3 className='footer-topic'>Мы в социальных сетях</h3> */}
                    <div className='social-icons'>
                        <div className='social-small-div'>
                            <a href='https://www.instagram.com/beyondborders.kg/'><img src='icons/linkedin.png' className='social-icon'/></a>
                            <a className='a-social' href='https://www.instagram.com/beyondborders.kg/'>Linkedin</a>
                        </div>
                        <div className='social-small-div'>
                            <a href='https://www.instagram.com/beyondborders.kg/'><img src='icons/youtube.png' className='social-icon'/></a>
                            <a className='a-social' href='https://www.instagram.com/beyondborders.kg/'>YouTube</a>
                        </div>
                    </div>
                </div>



                <div className='kontakt-div'>
                    {/* <h3 className='footer-topic'>Наши контакты</h3> */}
                    <div className='social-icons'>
                        <div className='kontakt-small-div'>
                            <a ><img src='icons/whatsapp.png' className='kontakt-icon'/></a>
                            <a className='a-kontakt'>+49 178 2950420</a>
                        </div>
                        <div className='kontakt-small-div'>
                            <a ><img src='icons/gmail.png' className='kontakt-icon'/></a>
                            <a className='a-kontakt'>beyondborderskg@gmail.com</a>
                        </div>
                        
                    </div>
                </div>

                
            </div>
        </footer>
    )
}