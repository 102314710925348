const API_HOST = 'https://beyondborderss.click/api';

let _csrfToken = null;

export async function getCsrfToken() {
    try{
        if (_csrfToken === null) {
            await fetch(`${API_HOST}/csrf/`, {
              credentials: 'include',
            });
            // const data = await response.json();
            // _csrfToken = data.csrfToken;
          }
    }catch(error){}
  }


export function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].toString().replace(/^([\s]*)|([\s]*)$/g, "");
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

