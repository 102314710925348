import './Header.css'


export default function Header() {
    function scrollToElement(elementId) {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }

    return (
        <header>
            <h1>BeyondBorders</h1>
            
            <div className='navigation'>
                <button onClick={() => scrollToElement('mainSection')}>Главная</button>
                <button onClick={() => scrollToElement('aboutSection')}>Мы</button>
                <button onClick={() => scrollToElement('mentorSection')}>Ментора</button>
                <button onClick={() => scrollToElement('footerSection')}>Контакты</button>
                {/* <a href='#footerSection' className='gg-a'>gg</a> */}
            </div>
            
            <div className='registration'>
                <button className='registration-btn' onClick={() => scrollToElement('registrationSection')}>Записаться на консультацию</button>
            </div>
        </header>
    );
}