import './MainContent.css'
import placeholder from './images/Placeholder.png'
import rectangle from './images/Rectangle.png'


export default function MainContent(){
    function scrollToElement(elementId) {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }

    return (
        <div className='main-inner-div'>
            <div className='left-label'>
                <h1 className='association-h1'>АССОЦИАЦИЯ СТУДЕНТОВ</h1>
                <h2 className='association-h2'>ИЗ ЦЕНТРАЛЬНОЙ АЗИИ ЗАРУБЕЖОМ</h2>
                <h4 className='association-h4'>Мы  поможем тебе 100% поступить в зарубежный ВУЗ!</h4>
                <div className='action-div'>
                    <button className='menti-btn' onClick={() => scrollToElement('registrationSection')}>Ищу ментора</button>
                    <button className='mentor-btn' onClick={() => scrollToElement('beMentorSection')}>Быть ментором</button>
                </div>
            </div>

            <div className='right-label'>
                {/* <video className='logo-animation' autoPlay muted playsinline>
                    <source src="video/BB_LOGO_ANIM.mp4" type="video/mp4" className='placeholder-img'/>
                    Your browser does not support the video tag or autoplay is disabled.

                </video> */}
                <img src='images/logo_bb.jpg' className='logo-animation'></img>
                <img src='images/oblako.png' className='rectangle-img'></img>
            </div>
        </div>
    )
}
