import './Registration.css'
import React, { useRef, useState } from 'react';

export default function Registration() {

    const [formValid, setFormValid] = useState({
        name: true,
        email: true,
        number: true,
        education: true,
        language: true,
        countries: true,
        grade: true,
    })

    const formRef = useRef();


    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const formData = new FormData(event.target);
        
        let formCheck = {
            name: true,
            email: true,
            number: true,
            education: true,
            language: true,
            countries: true,
            grade: true,
        }

        let isValid = true

        if(!formData.get('name').trim()){
            formCheck['name'] = false;
            isValid = false
        }
        if(!/^\S+@\S+\.\S+$/.test(formData.get('email'))){
            formCheck['email'] = false;
            isValid = false
        }
        if(!formData.get('number').trim()){
            formCheck['number'] = false;
            isValid = false
        }
        if(!formData.get('education').trim()){
            formCheck['education'] = false;
            isValid = false
        }
        if(!formData.get('language').trim()){
            formCheck['language'] = false;
            isValid = false
        }
        if(!formData.get('countries').trim()){
            formCheck['countries'] = false;
            isValid = false
        }
        if(!formData.get('grade').trim()){
            formCheck['grade'] = false;
            isValid = false
        }

        setFormValid(formCheck)
        
        if(isValid){
            try {
                const response = await fetch('https://beyondborderss.click/api/consultation/registration/client/', {
                    method: 'POST',
                    body: formData
                });

                if (response.ok) {
                    console.log('Form data submitted successfully');
                    window.alert("Форма успешно отправлена! В скором времени с вами свяжется наш координатор")
                    formRef.current.reset();
                } else {
                    console.error('Failed to submit form data');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    


    return (
        <div className='main-reg-dev' >
            <div className='formdiv'>
                <h3 className='formdiv-topic'>Регистрация на консультацию</h3>

                <form ref={formRef} onSubmit={handleSubmit}>

                    <label htmlFor="name">Имя*</label>
                    <input type="text" name="name" style={ formValid.name ? {borderColor: 'white'} : {borderColor: 'red'}} id="name" placeholder='Введи свое имя' className='name'/>

                    <label htmlFor="mail">Электронная почта*</label>
                    <input type="text" name="email" style={ formValid.email ? {borderColor: 'white'} : {borderColor: 'red'}} id="email" placeholder='Введи свою электронную почту' className='email'/>

                    <label htmlFor="number">Контактные данные*</label>
                    <p className='underlabel'>Номер телефона желательно с WhatsApp</p>

                    <input type="tel" name="number" style={ formValid.number ? {borderColor: 'white'} : {borderColor: 'red'}} id="number"  placeholder="Введи свой номер телефона" className='number'/>

                    <label htmlFor="education">Какое образование ты получаешь сейчас, и какое уже получил?*</label>
                    <p className='underlabel'>Укажи класс, курс и т. д.</p>
                    <input type="text" name="education" style={ formValid.education ? {borderColor: 'white'} : {borderColor: 'red'}} id="education" placeholder='Введи названия с классом либо курсом' className='education'/>

                    <label htmlFor="language">Знания языков с указанием уровня и если имеется с наименованием языкового сертификата*</label>
                    <p className='underlabel'>К примеру: Английский B2 IELTS, Турецкий A1 и  т. д.</p>
                    <input type="text" name="language" style={ formValid.language ? {borderColor: 'white'} : {borderColor: 'red'}} id="language" placeholder='Введи знания языков' className='language'/>

                    <label htmlFor="countries">Желаемые страны обучения*</label>
                    {/* <label><input type="checkbox" name="countries[]" value="Германия" className='country-input'/> Германия</label>
                    <label><input type="checkbox" name="countries[]" value="Автсрия" className='country-input'/> Автсрия</label>
                    <label><input type="checkbox" name="countries[]" value="Польша" className='country-input'/> Польша</label>
                    <label><input type="checkbox" name="countries[]" value="Турция" className='country-input'/> Турция</label>
                    <label><input type="checkbox" name="countries[]" value="Венгрия" className='country-input'/> Венгрия</label>
                    <label><input type="checkbox" name="countries[]" value="Катар" className='country-input'/> Катар</label>
                    <label><input type="checkbox" name="countries[]" value="США" className='country-input'/> США</label>
                    <label><input type="checkbox" name="countries[]" value="Франция" className='country-input'/> Франция</label> */}
                    <input type="text" name="countries" style={ formValid.countries ? {borderColor: 'white'} : {borderColor: 'red'}} id="countries" placeholder='Введи страны' className='countries'/>


                    <label htmlFor="grade">Средняя оценка в школе*</label>
                    <select style={ formValid.grade ? {borderColor: 'white'} : {borderColor: 'red'}} id="grade" name="grade" className='select-grade'>
                        <option value="5.0 - 4.6">5.0 - 4.6</option>
                        <option value="4.5 - 3.6">4.5 - 3.6</option>
                        <option value="3.5 - 2.6">3.5 - 2.6</option>
                    </select>

                    <label htmlFor="university">Желаемый ВУЗ?</label>
                    <p className='underlabel'>Если нет оставь поле пустым</p>
                    <input type="text" name="university" id="university" placeholder='Введи название университетов' className='university'/>

                    <label htmlFor="activity">Есть ли внешкольные активности?</label>
                    <p className='underlabel'>К примеру: Волонтёрство, первые места на олимпиадах, соревнованиях и  т. д.</p>
                    <input type="text" name="activity" id="activity" placeholder='Введи свои активности' className='activity'/>

                    <button type="submit" className='btn'>Отправить</button>
                </form>

                {/* <p style={{margin: "20px"}}>Статус отправки: {statusType ? statusType : "Отправь форму"}</p> */}
            </div>

            <div className='text-div'>
                <div className='win-div'>
                    <h4 className='kl-h4'>Ключевая идея оказания наших услуг заключается в создании системы менторства.</h4>
                </div>
                <p className='par-text'>Каждому желающему поступить через нашу ассоциацию будет 
                    назначен персональный ментор, который уже живет и учится в 
                    той же стране, а в лучшем случае - в том же городе и университете.</p>
                <p className='par-text2'>Мы поможем не только с поступлением, 
                    но и с визовыми вопросами, переездом, начальными бюрократическими 
                    вопросами в новой стране и адаптацией, что особенно важно для студента, 
                    только что прибывшего в новую страну.
                </p>

                <p className='par-text'>Ассоциация представляет собой общество молодых студентов, 
                уже обучающихся за границей преуспевающих не только в учебе, но и в других сферах.</p>
                <a href="https://www.instagram.com/beyondborders.kg/" className='a-news-img'>
                    <img src='./images/BB_NEWS.png' className='news_image'/>
                </a>
            </div>
        </div>
            
    );
}
