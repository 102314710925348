import './Mentor.css'


export default function Mentor({ name, country, image_src}){
    return (
        <div className='mentor-div'>
            <p className='p-country'>{country}</p>
            <p className='p-name'>{name}</p>
            <img src={image_src} className='mentor-image' alt=''></img>
        </div>
    )
}