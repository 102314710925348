export const mentors = [
    {
        name: 'Эмир',
        country: 'Мюнхен, Германия',
        image_src: 'mentors/Emir.png',
    },
    {
        name: 'Айбийке',
        country: 'Аризона, США',
        image_src: 'mentors/Aibike.png',
    },
    {
        name: 'Илима',
        country: 'Варшава, Польша',
        image_src: 'mentors/Ilima.png',
    },
    {
        name: 'Айжамал',
        country: 'Вена, Австрия',
        image_src: 'mentors/Aijamal.png',
    },
    {
        name: 'Акбар',
        country: 'Самсун, Турция',
        image_src: 'mentors/Akbar.png',
    },
    {
        name: 'Малика',
        country: 'Будапешт, Венгрия',
        image_src: 'mentors/Malika.png',
    },
    {
        name: 'Кундуз',
        country: 'Доха, Катар',
        image_src: 'mentors/Kunduz.png',
    },
    {
        name: 'Айсулуу',
        country: 'Вена, Австрия',
        image_src: 'mentors/Aisuluu.png',
    },
    {
        name: 'Саламат',
        country: 'Париж, Франция',
        image_src: 'mentors/Salamat.png',
    },
    
]
  
export const services = [
    {
        topic: 'Знакомство и разбор',
        longTopic: 'Первичный разбор и знакомство в виде онлайн консультации',
        text: "Консультация проводится с нашими координаторами для первичного знакомства, точной установки необходимых вам услуг и краткого разбора вашего случая.  Для регистрации на данную консультацию вам необходимо заполнить и отправить форму выше. Исходя из ваших данных и пожеланий, мы обсудим на консультации, чем мы сможем вам помочь.",
        icon: 'bi bi-0-circle-fill',
    },
    {
        topic: 'Подбор ментора',
        longTopic: 'Разбор вашего персонального кейса и подбор подходящего ментора',
        text: 'Этот этап также будет проводиться в форме встречи и знакомства с подобранным ментором. Где вы познакомитесь с ментором и определите дальнейшую стратегию в поступлении в желаемую страну',
        icon: 'bi bi-1-circle-fill',
    },
    {
        topic: 'Подача заявки',
        longTopic: 'Подбор университета и подходящей программы, подача заявки',
        text: 'На данном этапе, при отсутствии желаемых университетов, будет составлен список учебных заведений в желаемой стране. Подача заявки включает в себя составление и заполнение всех необходимых заявок, предоставление всех необходимых документов, а также, при необходимости, написание эссе, мотивационных писем и составление правильного резюме.',
        icon: 'bi bi-2-circle-fill',
    },
    {
        topic: 'Получение визы',
        longTopic: 'Подача на получение визы',
        text: 'Собираем все необходимые документы, соответствующие требованиям для получения визы, и в случае необходимости подготавливаемся к интервью.',
        icon: 'bi bi-3-circle-fill',
    },
    {
        topic: 'Переезд',
        longTopic: 'Переезд и помощь с первоначальной бюрократией',
        text: 'Оказываем поддержку в поиске жилья или общежития в городе обучения. Помогаем с сбором документов и приобретением авиабилетов. Предоставляем консультации относительно важных вопросов, которые нужно учесть при переезде. Смена страны проживания влечет за собой первоначальные бюрократические обязательства. Мы предоставим вам консультации и разъясним все аспекты, с которыми вы столкнетесь.',
        icon: 'bi bi-4-circle-fill',
    },
    {
        topic: 'Адаптация',
        longTopic: 'Адаптационный период в течении одного месяца',
        text: 'Ментор будет консультировать вас в течение первых месяцев по всем возникающим вопросам, помогая вам успешно адаптироваться к новой среде.',
        icon: 'bi bi-5-circle-fill',
    },
]