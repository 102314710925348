import './App.css';
import Header from './components/Header/Header';
import Registration from './components/Registration/Registraton';
import MainContent from './components/MainContent/MainContent';
import Mentor from './components/Mentor/Mentor';
import Footer from './components/Footer/Footer'
import ServiceContent from './components/ServiceContent/ServiceContent'
import BeMentorSection from './components/BeMentorSection/BeMentorSection'
import { useEffect } from 'react';
import * as csrf from './csrftoken'


import { mentors } from './data';



function App() {
  //to get this CSRF Token from djangorestframework in cookies
  useEffect(() => {
    csrf.getCsrfToken()
  }, []);
  
  return (
    <div className="App" id='mainSection'>
      <Header />
      <div className='main-div'>
        <MainContent />
      </div>
      <div className='registration-div' id='registrationSection'>
        <Registration />
      </div>

      <h1 className='mentors-h1' id='mentorSection'>Наши Ментора</h1>
      <div className='metors-wrapper'>
        <div className='mentors'>
          {mentors.map((mentor) => (
              <Mentor key={mentor.name} {...mentor} />
            ))}
        </div>
      </div>
      


      <ServiceContent />

      <BeMentorSection />


      <Footer />


    </div>
  );
}

export default App;
