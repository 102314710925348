import './BeMentorSection.css'
import React, { useRef, useState } from 'react';

export default function BeMentorSection() {


    const [formValid, setFormValid] = useState({
        name: true,
        number: true,
        message: true,
    })

    const formRef = useRef();

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const formData = new FormData(event.target);
        
        let formCheck = {
            name: true,
            number: true,
            message: true,
        }

        let isValid = true

        if(!formData.get('name').trim()){
            formCheck['name'] = false;
            isValid = false
        }
        if(!formData.get('number').trim()){
            formCheck['number'] = false;
            isValid = false
        }
        if(!formData.get('message').trim()){
            formCheck['message'] = false;
            isValid = false
        }
        
        setFormValid(formCheck)
        
        if(isValid){
            try {
                const response = await fetch('https://beyondborderss.click/api/consultation/registration/mentor/', {

                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    console.log('Form data submitted successfully');
                    window.alert("Форма успешно отправлена! В скором времени с вами свяжется наш координатор")
                    formRef.current.reset();
                } else {
                    console.error('Failed to submit form data');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };



    return (
        <div className='main-bementor'  id='beMentorSection'>
            <h1 className='bementors-h1' >Хочешь быть ментором?</h1>

            <div className='bementor-big-div'>
                <div className='main-reg-dev' >

                    <div className='bementor-div'>
                        <div className='win-div'>
                            <h4 className='kl-h4'>Мы также ищем менторов из разных стран. Если тебе это интересно, заполни форму, и мы свяжемся с тобой</h4>
                        </div>
                        <p className='par-text'>Задача ментора в первую очередь заключается в предоставлении студенту 
                        необходимых знаний и советов, которые помогут ему успешно поступить и адаптироваться в 
                        стране, где он обучается. Это может включать в себя:
                        Помощь в подготовке к поступлению, включая советы по выбору подходящего университета и программы 
                        обучения, поддержка в процессе поступления в университет, включая помощь с заполнением анкет и 
                        подготовкой документов, помощь в оформлении визы, если это необходимо, и сопутствующих административных 
                        процедурах, поддержка в поиске жилья и предоставление советов и поддержки в процессе адаптации 
                        к новой среде и образу жизни.</p>
                        <p className='par-text2'>

                        Если ты поступил в университет сам и хочешь помогать своим соотечественникам, присоединяйся к нам.
                        </p>
                    </div>
                    
                    <div className='bementor-formdiv'>
                        <h3 className='bementor-f-topic'>Заявка для будущего ментора</h3>

                        <form ref={formRef} onSubmit={handleSubmit}>
                            <label htmlFor="name">Имя*</label>
                            <input type="text" name="name" style={ formValid.name ? {borderColor: 'white'} : {borderColor: 'red'}} id="name" placeholder='Введи свое имя' className='name'/>

                            <label htmlFor="number">Номер телефона*</label>
                            <input type="tel" name="number" style={ formValid.number ? {borderColor: 'white'} : {borderColor: 'red'}} id="number"  placeholder="Введи свой номер телефона" className='number'/>

                            <label htmlFor="message">О себе*</label>
                            <textarea style={ formValid.message ? {borderColor: 'white'} : {borderColor: 'red'}} id="message" name="message" rows="10" cols="70" placeholder="Расскажи о себе" className='text-bementor'></textarea>
                            {/* <input type="text" name="text" id="text"  placeholder="Введи текстовое предложение" className='be-mentor-text'/> */}


                            <button type="submit" className='btn'>Отправить</button>
                        </form>

                        {/* <p style={{margin: "20px"}}>Статус отправки: {statusType ? statusType : "Отправь форму"}</p> */}
                    </div>

                    
                </div>
            </div>
        </div>
    )
}
